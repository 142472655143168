import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const NODE_ENV = process.env.NODE_ENV;

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/redirect`}
          component={lazy(() => import(`./admin/redirect`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/proxyTypes`}
          component={lazy(() => import(`./admin/proxyTypes`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/price-change-history`}
          component={lazy(() => import(`./admin/price-change-history`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/notifications`}
          component={lazy(() => import(`./admin/notifications`))}
        />

        <Route path={`${APP_PREFIX_PATH}/tasks`} component={lazy(() => import(`./admin/tasks`))} />

        <Route
          path={`${APP_PREFIX_PATH}/package-proxy`}
          component={lazy(() => import(`./admin/package-proxy`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./admin/orders`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/block/payment`}
          component={lazy(() => import(`./admin/blocked-payment`))}
        />

        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./admin/users`))} />

        <Route
          path={`${APP_PREFIX_PATH}/metaPage`}
          component={lazy(() => import(`./admin/metaPage`))}
        />

        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./admin/pages`))} />

        <Route
          path={`${APP_PREFIX_PATH}/settings/crm`}
          component={lazy(() => import(`./admin/crm/`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/seo-default`}
          component={lazy(() => import(`./admin/seo-default/`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/meta-tags`}
          component={lazy(() => import(`./admin/meta-tags/`))}
        />

        <Route path={`${APP_PREFIX_PATH}/blog`} component={lazy(() => import(`./admin/blog`))} />

        <Route
          path={`${APP_PREFIX_PATH}/country`}
          component={lazy(() => import(`./admin/country`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/currency`}
          component={lazy(() => import(`./admin/currency`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/currency-rate`}
          component={lazy(() => import(`./admin/currency-rate`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/date-format-template`}
          component={lazy(() => import(`./admin/date-format-template`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/discount`}
          component={lazy(() => import(`./admin/discount`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/email-template`}
          component={lazy(() => import(`./admin/email-template`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/email-spam`}
          component={lazy(() => import(`./admin/email-spam`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/popup-notifications`}
          component={lazy(() => import(`./admin/popup-notifications`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/captcha`}
          component={lazy(() => import(`./admin/captcha`))}
        />

        <Route path={`${APP_PREFIX_PATH}/faq`} component={lazy(() => import(`./admin/faq`))} />

        <Route
          path={`${APP_PREFIX_PATH}/faq-group`}
          component={lazy(() => import(`./admin/faq-group`))}
        />

        <Route path={`${APP_PREFIX_PATH}/goal`} component={lazy(() => import(`./admin/goal`))} />

        <Route
          path={`${APP_PREFIX_PATH}/goal-group`}
          component={lazy(() => import(`./admin/goal-group`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/proxy-package`}
          component={lazy(() => import(`./admin/proxy-package`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/mobile-operator`}
          component={lazy(() => import(`./admin/mobile-operator`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/payment-system`}
          component={lazy(() => import(`./admin/payment-system`))}
        />

        <Route path={`${APP_PREFIX_PATH}/price`} component={lazy(() => import(`./admin/price`))} />

        <Route
          path={`${APP_PREFIX_PATH}/promocode`}
          component={lazy(() => import(`./admin/promocode`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/proxy-authentication`}
          component={lazy(() => import(`./admin/proxy-authentication`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/rent-period`}
          component={lazy(() => import(`./admin/rent-period`))}
        />

        <Route path={`${APP_PREFIX_PATH}/files`} component={lazy(() => import(`./admin/files`))} />

        <Route
          path={`${APP_PREFIX_PATH}/review`}
          component={lazy(() => import(`./admin/review`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/seo-block`}
          component={lazy(() => import(`./admin/seo-block`))}
        />

        {NODE_ENV !== "development" && (
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/country`} />
        )}

        {NODE_ENV === "development" && (
          <>
            <Route
              path={`${APP_PREFIX_PATH}/dashboards`}
              component={lazy(() => import(`./dashboards`))}
            />
            <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
            <Route
              path={`${APP_PREFIX_PATH}/components`}
              component={lazy(() => import(`./components`))}
            />
            <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
            <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
            <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
            <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />

            <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/orders`} />
          </>
        )}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
